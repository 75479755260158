<template>
  <li class="list-group-item">
    <ElementItem class="ana-item" :item="item" @handlerUpdate="$emit('handlerUpdate', $event)" />

    <draggable
      v-model="item.children"
      tag="ul"
      class="list-group"
      handle=".draggable-task-handle"
      @end="handlerKategoriSiraChange"
    >
      <li v-for="(subitem, index) in item.children" :key="index">
        <ElementItem class="sub-item" :item="subitem" @handlerUpdate="$emit('handlerUpdate', $event)" />
      </li>
    </draggable>
  </li>
</template>

<script>
import store from '@/store';
import ElementItem from './ElementItem.vue';
import { defineComponent, toRefs } from '@vue/composition-api';
import draggable from 'vuedraggable';
import { useToast } from 'vue-toastification/composition';

export default defineComponent({
  components: {
    ElementItem,
    draggable,
  },
  props: {
    item: {
      type: Object,
      requied: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();

    const { item } = toRefs(props);
    // expo.handlerKategoriSiraChange = () => {
    // };

    expo.handlerKategoriSiraChange = () => {
      store
        .dispatch('kategoriSiraGuncelle', { kategori: item.value.children })
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Sıra güncelleme başarılı.', { position: 'bottom-left' });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
