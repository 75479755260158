<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6" v-show="diller.length != 1">
          <b-form-group label="Dil">
            <v-select
              v-model="defaultDil"
              :options="diller"
              :reduce="(diller) => diller.lang"
              label="title"
              :clearable="false"
              :disabled="!form._id"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Ana Kategori">
            <v-select
              v-model="form.kategori_k_no"
              :options="kategoriler"
              :reduce="(kategoriler) => kategoriler.k_no"
              label="baslik"
              :clearable="true"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12 pt-3">
          <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Başlık">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="baslik"
                v-model="form.icerik[defaultDil].baslik"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
                @input="handlerBaslikChange"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" class="pt-3">
          <b-form-group label="Aciklama">
            <b-form-textarea
              size="lg"
              class="rounded-0"
              rows="2"
              ref="baslik"
              v-model="form.icerik[defaultDil].aciklama"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider name="Ön Açıklama (Description)" :rules="{ required: false, max: 300 }" v-slot="validationContext">
            <b-form-group
              :label="`Ön Açıklama (Description) ${onAciklamaLenght} / 300`"
              description="Ön Açıklama 160 karakteri geçmemelidir"
            >
              <b-form-textarea
                class="rounded-0"
                ref="on_aciklama"
                v-model="form.icerik[defaultDil].description"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
                @input="handlerOnAcklamaChange"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Anahtar Kelimeler (Keywords)">
            <b-form-tags size="lg" v-model="form.icerik[defaultDil].keywords" input-id="tags-basic" class="rounded-0" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="pt-3">
          <b-form-group label="Turu">
            <v-select
              v-model="form.turu"
              :options="Turler"
              :reduce="(tur) => tur.value"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="pt-3">
          <b-form-group label="Anasayfa Gösterim">
            <v-select
              v-model="form.anasayfa"
              :options="AnasayfaStatu"
              :reduce="(ana) => ana.value"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="pt-3">
          <b-form-group label="Avatar Gösterim">
            <v-select
              v-model="form.avatar"
              :options="AnasayfaStatu"
              :reduce="(ana) => ana.value"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="İcon">
            <b-form-input size="lg" class="rounded-0" ref="video" v-model="form.icon" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            label="Slug (Seflink)"
            description="Bu link yapısı bir kere oluşturulacaktır. Düzenleme yaptığınız takdirde Google,Yandex vb siteler indexlemeyi ortalama 1 ay için de tekrardan bu sayfayı indexleme alacaktır."
          >
            <b-input-group>
              <b-form-input size="lg" class="rounded-0" v-model="form.icerik[defaultDil].slug" :disabled="!slug_duzenle" />
              <b-input-group-append v-if="form.k_no">
                <b-button squared variant="outline-success" @click="slug_duzenle = !slug_duzenle">
                  <i class="fad fa-pencil"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <div class="d-flex justify-content-between align-items-center py-3">
            <label for="statu" class="h6">Kullanım Durumu</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch size="lg" />
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="d-flex justify-content-between align-items-center py-3">
            <label for="statu" class="h6">Seo Optimize</label>
            <b-form-checkbox :checked="form.seo" v-model="form.seo" switch size="lg" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import slugify from 'slugify';
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    kategoriler: {
      type: Array,
      required: true,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    const { updateData } = toRefs(props);
    expo.onAciklamaLenght = ref(0);
    expo.slug_duzenle = ref(false);
    expo.AnasayfaStatu = ref([
      {
        value: false,
        title: 'Hayır',
      },
      {
        value: true,
        title: 'Evet',
      },
    ]);

    expo.form = ref({
      _id: null,
      k_no: null,
      kategori_k_no: null,
      icerik: {},
      gorsel: null,
      turu: 'sayfa',
      icon: null,
      statu: true,
      seo: true,
    });

    expo.Turler = ref([
      { title: 'Sayfa', value: 'sayfa' },
      { title: 'Kategori', value: 'kategori' },
      { title: 'Ürün', value: 'urun' },
      { title: 'Transfer', value: 'transfer' },
      { title: 'Arac Kira', value: 'arac_kira' },
      { title: 'Blog', value: 'blog' },
      { title: 'Galeri', value: 'galeri' },
    ]);

    expo.handlerOnAcklamaChange = (event) => {
      expo.onAciklamaLenght.value = event.length > 0 ? event.length : 0;
    };

    expo.handlerBaslikChange = (event) => {
      const slug = slugify(event, { lower: true });
      if (expo.form.value.k_no && expo.slug_duzenle.value) expo.form.value.icerik[expo.defaultDil.value].slug = slug;
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('kategoriGuncelle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
            context.emit('updateRefresh', res.data.data);
            expo.slug_duzenle.value = false;
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) {
          expo.form.value = { ...val };
          if (expo.form.value.icerik[expo.defaultDil.value].baslik == '') expo.slug_duzenle.value = true;
        } else {
          expo.resetForm();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
